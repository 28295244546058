<template>
	<div class="animated fadeIn">
		<b-card class="card-border mt-4">
			<b-card-title><i class="fa fa-pie-chart"></i> Asset Pool Distribution</b-card-title>
			<b-card-sub-title>Handles the asset pool distribution based on company connections within the supply chain</b-card-sub-title>
			<div fluid class="px-2 mt-4">
				<loading :active.sync="isLoading" loader="spinner" color="#20A8D8" :is-full-page="false" />

				<!-- Filter  -->
				<b-row class="mt-2">
					<b-col sm="12" md="3" lg="3">
						<b-button v-b-popover.hover.right="'Toggle to show/hide filter options'" v-b-toggle.collapse-1
							class="filter">
							FILTER OPTIONS
						</b-button>
					</b-col>
					<b-col sm="12">
						<!-- Collapsible Filter Options -->
						<b-collapse id="collapse-1" class="mt-2" visible>
							<b-card>
								<b-row no-gutters>
									<b-col lg="4" md="6" sm="12" class="mr-4">
										<b-form-group label="Company">
											<v-select class="style-chooser" label="text"
												placeholder=" - Please select - "
												:options="filterByOptions.companyItems"
												:reduce="(company) => company.value" v-model="filterBy.company">
												<template v-slot:no-options="{ search, searching }">
													<template v-if="searching">
														No results found for
														<em>
															<strong>{{ search }}</strong>
														</em>
													</template>
													<em :style="{ opacity: 0.5 }" v-else>
														Start typing to search for a company
													</em>
												</template>
											</v-select>
										</b-form-group>
									</b-col>
									<b-col lg="4" md="6" sm="12" class="mr-4">
										<b-form-group label="Asset Type">
											<v-select class="style-chooser" label="text"
												placeholder=" - Please select - "
												:options="filterByOptions.assetTypeItems"
												:reduce="(assetType) => assetType.value" v-model="filterBy.assetType">
												<template v-slot:no-options="{ search, searching }">
													<template v-if="searching">
														No results found for
														<em>
															<strong>{{ search }}</strong>
														</em>
													</template>
													<em :style="{ opacity: 0.5 }" v-else>
														Start typing to search for a asset type
													</em>
												</template>
											</v-select>
										</b-form-group>
									</b-col>
									<b-col lg="4" md="6" sm="12" class="mr-4">
										<b-form-group label="Connected Company">
											<v-select class="style-chooser" label="text"
												placeholder=" - Please select - "
												:options="filterByOptions.connectedCompanyItems"
												:reduce="(company) => company.value"
												v-model="filterBy.connectedCompany">
												<template v-slot:no-options="{ search, searching }">
													<template v-if="searching">
														No results found for
														<em>
															<strong>{{ search }}</strong>
														</em>
													</template>
													<em :style="{ opacity: 0.5 }" v-else>
														Start typing to search for a company
													</em>
												</template>
											</v-select>
										</b-form-group>
									</b-col>
									<b-col lg="4" md="6" sm="12" class="mr-4">
										<b-form-group label="Connected Storage Location">
											<v-select class="style-chooser" label="text"
												placeholder=" - Please select - "
												:options="filterByOptions.connectedStorageLocationItems"
												:reduce="(loc) => loc.value"
												v-model="filterBy.connectedStorageLocation">
												<template v-slot:no-options="{ search, searching }">
													<template v-if="searching">
														No results found for
														<em>
															<strong>{{ search }}</strong>
														</em>
													</template>
													<em :style="{ opacity: 0.5 }" v-else>
														>Start typing to search for a storage location
													</em>
												</template>
											</v-select>
										</b-form-group>
									</b-col>
								</b-row>
								<b-row no-gutters>
									<b-col sm="12">
										<b-button class="mr-1" variant="success" @click="onFilterRequest">
											Generate
										</b-button>
										<b-button class="mr-1" variant="primary" @click="resetFilters">
											Reset
										</b-button>
									</b-col>
								</b-row>
							</b-card>
						</b-collapse>
					</b-col>
				</b-row>

				<!-- Select Actions and Items Per Page Options -->
				<b-row>
					<b-col sm="6" md="3" class="mt-4 mb-2">
						<b-dropdown text=" Select Actions " variant="dark" slot="append">
							<b-dropdown-item>
								<json-excel :data="exportData" :fields="exportFields" type="xls"
									:name="fileName + '.xls'">
									Export Asset Pool Distributions in Excel
								</json-excel>
							</b-dropdown-item>
							<b-dropdown-item>
								<json-excel :data="exportData" :fields="exportFields" type="csv"
									:name="fileName + '.csv'">
									Export Asset Pool Distributions to CSV
								</json-excel>
							</b-dropdown-item>
						</b-dropdown>
					</b-col>
					<b-col sm="6" md="4" offset-md="5" class="mt-4 mb-2 text-md-right">
						<b-input-group prepend="Show" append="/ Page">
							<b-form-select :options="pageOptions" v-model="perPage" />
						</b-input-group>
					</b-col>
				</b-row>

				<b-table show-empty striped hover :items="items" :fields="fields" :current-page="currentPage"
					:per-page="perPage" :filter="filter" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc"
					:sort-direction="sortDirection" responsive>
					<template v-slot:cell(connection)="row">
						{{ row.item.connection }}
					</template>
					<template v-slot:cell(type)="row">
						<span v-if="row.item.type === 'On-Hand'">
							<b-badge variant="primary">{{ row.item.type }}</b-badge>
						</span>
						<span v-else>
							<b-badge variant="warning">{{ row.item.type }}</b-badge>
						</span>
					</template>
					<template v-slot:cell(total)="row">
						<span class="numFont">
							{{ row.item.total }}
						</span>
					</template>

					<template v-slot:cell(actions)="row">
						<span class="text-nowrap">
							<b-button size="sm" v-b-tooltip.hover.top="'Show/Hide Other Details'" variant="dark"
								@click.stop="row.toggleDetails" class="mr-1">
								<i class="fa fa-eye-slash" v-if="row.detailsShowing"></i>
								<i class="fa fa-eye" v-else></i>
							</b-button>
						</span>
					</template>

					<template slot="row-details" slot-scope="row">
						<AssetPoolDistributionDetailsView :row="row" />
					</template>
				</b-table>

				<b-row>
					<b-col md="8" sm="12" class="my-1">
						<span class="total-display">Total: {{ totalRows ? totalRows.toLocaleString() : 0 }}</span>
					</b-col>
					<b-col md="4" sm="12">
						<b-pagination align="right" :total-rows="totalRows" :per-page="perPage" v-model="currentPage" />
					</b-col>
				</b-row>
			</div>
		</b-card>
	</div>
</template>

<script>
// Components
import AssetPoolDistributionDetailsView from './assetPoolDistribution/AssetPoolDistributionDetailsView';

// Util
import { DateUtil } from '@/utils/dateutil';
import { DropDownItemsUtil } from '@/utils/dropDownItemsUtil';

// API
import assetPoolDistributionApi from '@/api/assetPoolDistributionApi';

// Others
import config from '@/config/env-constants';
import EventBus from '@/shared/event-bus';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import JsonExcel from 'vue-json-excel';
import _ from 'lodash';

export default {
	name: 'asset-pool-distribution',
	components: {
		AssetPoolDistributionDetailsView,
		Loading,
		JsonExcel,
	},
	data() {
		return {
			items: [],
			fields: [
				{
					key: 'company',
					label: 'Company',
					sortable: true,
				},
				{
					key: 'connection',
					sortable: true,
				},
				{
					key: 'type',
					sortable: true,
				},
				{
					key: 'assetType',
					sortable: true,
				},
				{
					key: 'total',
					label: 'Total Assets',
					sortable: true,
				},
				{
					key: 'actions',
					thClass: 'text-center'
				}
			],
			currentPage: 1,
			perPage: 10,
			totalRows: 0,
			pageOptions: [5, 10, 15, 25, 50, 100],
			sortBy: null,
			sortDesc: false,
			sortDirection: 'asc',
			filter: null,

			defaultFilterBy: {
				company: config.companyDefaultValue,
				connectedCompany: config.companyDefaultValue,
				connectedStorageLocation: config.storageLocationDefaultValue,
				assetType: config.assetTypeDefaultValue,
			},
			filterBy: {
				company: config.companyDefaultValue,
				connectedCompany: config.companyDefaultValue,
				connectedStorageLocation: config.storageLocationDefaultValue,
				assetType: config.assetTypeDefaultValue,
			},
			prevFilter: {},
			filterByOptions: {
				companyItems: [],
				connectedCompanyItems: [],
				connectedStorageLocationItems: [],
				assetTypeItems: [],
			},

			allCompaniesObj: {},
			allConnectedCompaniesObj: {},
			allConnectionsObj: {},
			allStorageLocationsObj: {},
			allAssetTypesObj: {},

			allAssetPoolDistributionsObj: {},
			selAssetPoolDistribution: {},

			isSuperAdmin: this.$store.getters.isSuperAdmin,
			isViewer: this.$store.getters.isViewer,
			loggedUserCompany: this.$store.getters.loggedUserCompany,
			loggedUser: this.$store.getters.loggedUser,
			// Check for loader
			isLoading: false,
		};
	},
	watch: {
		"filterBy.company": function (newVal) {
			if (newVal && newVal.id !== null) {
				let connectionsObj = _.filter(this.allConnectionsObj, o => {
					return o.companyId === newVal.id;
				});

				// add the connected companies
				let connectedCompanies = [];
				if (connectionsObj) {
					connectedCompanies = _.map(connectionsObj, 'connectedCompany');
				}

				// add the current company as well
				connectedCompanies.push(this.allCompaniesObj[newVal.id]);

				this.filterByOptions.connectedCompanyItems = DropDownItemsUtil.retrieveCompanies(connectedCompanies);

				// reset dependent filter options
				this.filterBy.connectedCompany = { ...config.companyDefaultValue };
				this.filterBy.connectedStorageLocation = { ...config.storageLocationDefaultValue };
			} else {
				this.filterByOptions.connectedCompanyItems = DropDownItemsUtil.retrieveCompanies(this.allCompaniesObj);
				this.filterByOptions.connectedStorageLocationItems = DropDownItemsUtil.retrieveStorageLocations(this.allStorageLocationsObj);
			}
		},
		"filterBy.connectedCompany": function (newVal) {
			if (newVal && newVal.id !== null) {
				let locsObj = _.filter(this.allStorageLocationsObj, o => {
					return o.companyId === newVal.id;
				});
				this.filterByOptions.connectedStorageLocationItems = DropDownItemsUtil.retrieveStorageLocations(locsObj);

				// reset dependent filter options
				this.filterBy.connectedStorageLocation = { ...config.storageLocationDefaultValue };
			} else {
				this.filterByOptions.connectedStorageLocationItems = DropDownItemsUtil.retrieveStorageLocations(this.allStorageLocationsObj);
			}
		},
	},
	computed: {
		/**
		 * Returns the set of data to be included in the export. For now this just
		 * returns the data as is.
		 *
		 * @returns {Array} the set of data to be included in the export.
		 */
		exportData() {
			return this.items;
		},

		/**
		 * Derives the field information based from the data table configuration.
		 *
		 * @returns {object} the fields to be included in the export.
		 */
		exportFields() {
			return {
				Company: 'company',
				Connection: 'connection',
				Type: 'type',
				'Asset Type': 'assetType',
				Total: 'total',
				'Date Created': 'Date Created',
				'Date Updated': 'Date Updated',
				Notes: 'notes',
			};
		},

		fileName() {
			let currTimeStamp = DateUtil.getCurrentTimestamp();
			return 'AssetPoolDistribution-' + DateUtil.getDateInDDMMYYYYHHSSFormat(currTimeStamp);
		},
	},
	mounted() {
		setTimeout(async () => {
			try {
				// Filter Access
				if (this.$store.getters.isScanner
					|| this.$store.getters.isAccounting
					|| this.$store.getters.isApprover
					|| this.$store.getters.isMaintenance) {
					this.$router.push('/dashboard');
					this.$toaster.warning('You are not allowed to access this page.');
				}

				// show loading indicator
				this.isLoading = true;

				this.allCompaniesObj = { ...this.$store.getters.companies };
				this.allConnectedCompaniesObj = this.isSuperAdmin ? { ...this.allCompaniesObj } : { ...this.$store.getters.connectedCompanies };
				this.allStorageLocationsObj = { ...this.$store.getters.storageLocations, ...this.$store.getters.connectedStorageLocations };
				this.allConnectionsObj = { ...this.$store.getters.connections };
				this.allAssetTypesObj = { ...this.$store.getters.assetTypes };

				this.filterByOptions.companyItems = DropDownItemsUtil.retrieveCompanies(this.allCompaniesObj);

				if (this.isSuperAdmin) {
					this.filterByOptions.connectedCompanyItems = DropDownItemsUtil.retrieveCompanies(this.allCompaniesObj);
				} else {
					let allCompaniesObj = { ...this.allCompaniesObj, ...this.allConnectedCompaniesObj };
					this.filterByOptions.connectedCompanyItems = DropDownItemsUtil.retrieveCompanies(allCompaniesObj);

					this.filterBy.company = DropDownItemsUtil.getCompanyItem(this.loggedUserCompany);
					this.defaultFilterBy.company = DropDownItemsUtil.getCompanyItem(this.loggedUserCompany);
				}

				this.filterByOptions.connectedStorageLocationItems = DropDownItemsUtil.retrieveStorageLocations(this.allStorageLocationsObj);
				this.filterByOptions.assetTypeItems = DropDownItemsUtil.retrieveAssetTypes(this.allAssetTypesObj, true);

				await this.resetFilters();
				await this.retrieveData();

			} catch (_error) {
				this.$toaster.error('Error loading data. Please reload the page again.');
			} finally {
				// hide loading indicator
				this.isLoading = false;
			}

		}, config.timeout);
	},
	methods: {
		async onFilterRequest() {
			if (!_.isEqual(this.filterBy, this.prevFilter)) {
				await this.retrieveData();
				this.prevFilter = { ...this.filterBy };
			}
		},
		async resetFilters() {
			if (!_.isEqual(this.filterBy, this.defaultFilterBy)) {
				// reset to default
				this.filterBy = { ...this.defaultFilterBy };
				this.prevFilter = { ...this.filterBy };

				// reset validation
				this.$validator.reset();
				this.errors.clear();

				await this.retrieveData();
			}
		},
		async retrieveData() {
			try {
				// show loading indicator
				this.isLoading = true;

				let filter = { ...this.filterBy };
				filter.companyId = this.loggedUserCompany.id;

				if (this.isSuperAdmin) {
					const { data } =
						await assetPoolDistributionApi.getAssetPoolDistributions(
							filter,
							config.view.ADMIN,
							this.loggedUser.id
						);
					this.allAssetPoolDistributionsObj = data.assetPoolDistributions;
				} else {
					const { data } =
						await assetPoolDistributionApi.getAssetPoolDistributions(
							filter,
							config.view.COMPANY,
							this.loggedUser.id
						);
					this.allAssetPoolDistributionsObj = data.assetPoolDistributions;
				}

				this.filterDistributions(this.allAssetPoolDistributionsObj);

			} catch (_error) {
				this.$toaster.error('Error loading data. Please reload the page again.');
			} finally {
				// hide loading indicator
				this.isLoading = false;
			}
		},
		filterDistributions(allAssetPoolDistributionsObj) {
			let filteredObj = { ...allAssetPoolDistributionsObj };

			_.forEach(filteredObj, (distribution, id) => {
				let companyIdFilter = this.filterBy.company.id;
				if (companyIdFilter !== null && companyIdFilter !== distribution.companyId) {
					delete filteredObj[id];
				}

				let connectedCompanyIdFilter = this.filterBy.connectedCompany.id;
				if (connectedCompanyIdFilter !== null && connectedCompanyIdFilter !== distribution.connectedCompanyId) {
					delete filteredObj[id];
				}

				let connectedLocIdFilter = this.filterBy.connectedStorageLocation.id;
				if (connectedLocIdFilter !== null && connectedLocIdFilter !== distribution.connectedStorageLocationId) {
					delete filteredObj[id];
				}

				let assetTypeIdFilter = this.filterBy.assetType.id;
				if (assetTypeIdFilter !== null && assetTypeIdFilter !== distribution.assetTypeId) {
					delete filteredObj[id];
				}
			});

			this.processAssetPoolDistribution(filteredObj);
		},
		processAssetPoolDistribution(assetPoolDistributions) {
			this.allAssetPoolDistributionsObj = assetPoolDistributions;

			this.items = Object.values(this.allAssetPoolDistributionsObj);
			this.items = _.sortBy(this.items, [
				'company',
				'connectedCompany',
				'connectedStorageLocation',
				'assetType',
			]);
			this.totalRows = this.items.length;

			this.items.forEach((item) => {
				if (item.companyId === item.connectedCompanyId) {
					item['type'] = 'On-Hand';
				} else {
					item['type'] = 'Transferred';
				}

				item['connection'] = item.connectedCompany + ' - ' + item.connectedStorageLocation;
				item['Date Created'] = this.getFormattedDateWithTime(item.dateCreated);
				item['Date Updated'] = this.getFormattedDateWithTime(item.dateUpdated);
			});

			// update cache
			this.$store.dispatch(
				'setAllAssetPoolDistributions',
				this.allAssetPoolDistributionsObj
			);
		},

		getFormattedDateWithTime(date) {
			return DateUtil.getFormattedDateWithTime(date);
		},
	},
	beforeDestroy() {
		EventBus.$off('onCloseAddAssetPoolDistribution');
		EventBus.$off('onCloseEditAssetPoolDistribution');
	},
};
</script>